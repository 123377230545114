.titolo{
    font-size: 60px;
    font-weight: 600;
    line-height: 60px; /* or 107% */
    letter-spacing: -1.9px;
    color: #0f0f0f;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media all and (min-width: 768px) and (max-width: 1002px) {
    .titolo{
        font-size: 60px;
    }
}

@media all and (min-width: 576px) and (max-width: 767px) {
    .titolo{
        font-size: 60px;
    }
}

@media all and (min-width: 421px) and (max-width: 575px) {
    .titolo{
        font-size: 60px;
    }
}


@media all and (max-width: 420px) {
    /* For mobile phones: */
    .titolo{
        font-size: 42px;
        line-height: 42px; /* or 107% */
    }
    .tagline{
        font-size: 12px;
    }
}